<template>
  <ion-page>
    <ion-content>
      <div class="app bg-slate-50 h-full">
        <div class="max-w-screen-lg mx-auto h-full">
          <main class="main-wrapper h-full">
            <div class="main-content h-full">
              <div class="feedback-header grid gap-4 grid-cols-3 grid-rows-1 text-white px-5 pt-3 pb-2">
                <div class="text-left">
                  <a href="/" class="inline-flex items-center no-underline">
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 48 48" fill="currentColor"><path d="M20 44 0 24 20 4l2.8 2.85L5.65 24 22.8 41.15Z"/></svg>               
                    </span>
                    <span class="font-medium">Back</span>
                  </a>
                </div>
                <div class="text-center">
                  <span class="font-medium">Feedback</span>
                </div>
                <div class="text-right"></div>
              </div>

              <div class="feedback-screen flex flex-col justify-end">
                <div class="feedback-btn-group">
                  <router-link to="/feedback-form" class="flex items-center justify-between px-5 py-3 feedback-btn feedback-btn-white leading-5 no-underline">
                    <span class="pr-8 uppercase font-medium">Problem with this app?</span>
                    <span><svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 48 48" fill="currentColor"><path d="m15.2 43.9-2.8-2.85L29.55 23.9 12.4 6.75l2.8-2.85 20 20Z"/></svg></span>
                  </router-link>
                  <a @click="openExternalSite('https://www.thehopeline.com/privacy-policy/')" class="flex items-center justify-between px-5 py-3 feedback-btn feedback-btn-dark leading-5 no-underline">
                    <span class="pr-8 uppercase font-medium">Terms of use and <br>privacy statement</span>
                    <span><svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 48 48" fill="currentColor"><path d="m15.2 43.9-2.8-2.85L29.55 23.9 12.4 6.75l2.8-2.85 20 20Z"/></svg></span>
                  </a>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { IonContent, IonPage } from "@ionic/vue";
import { Browser } from '@capacitor/browser';

const openExternalSite = async (url) => {
  await Browser.open({ url: url });
};
window.Intercom('update', {
  hide_default_launcher: true,
});
</script>

<style scoped>
.main-wrapper {
  background-color: #1e435b;
}
.main-content {
  background-image: url(../../public/images/feedback-image.png);
  background-repeat-y: repeat;
  background-size: cover;
  background-position: top center;  
}
.feedback-header {
  background-color: #1e435b;
}
.feedback-screen {
  height: calc(100% - 50px);
}
.feedback-btn-white {
    background-color: #dadfe2;
    color: #1e435b;
}
.feedback-btn-dark {
    background-color: #1e435b;
    color: #ffffff;
}
.feedback-btn {
  height: 72px;
}
.feedback-btn-group {
  border-top: 1px solid #1e435b;
}
</style>
<template>
  <ion-page>
    <ion-content>
      <div class="app bg-slate-50 h-full">
        <div class="max-w-screen-lg mx-auto h-full">
          <main class="main-wrapper h-full">
            <div class="main-content flex flex-col justify-between h-full">
              <div class="flex flex-col">
              <div class="feedback-header grid gap-4 grid-cols-3 grid-rows-1 text-white px-5 pt-3 pb-2">
                <div class="text-left">
                  <router-link to="/" class="inline-flex items-center no-underline">
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 48 48" fill="currentColor"><path d="M20 44 0 24 20 4l2.8 2.85L5.65 24 22.8 41.15Z"/></svg>               
                    </span>
                    <span class="font-medium">Back</span>
                  </router-link>
                </div>
                <div class="text-center">
                  <span class="font-medium">GetHelp</span>
                </div>
                <div class="text-right"></div>
              </div>
              
                <div class="flex justify-center pt-8 pb-6 px-6 sm:pt-18">
                  <div class="w-2/4 mx-auto sm:w-1/3">
                    <img src="images/HopeLine_Logo6_StkTC.png" alt="" />
                  </div>
                </div>
                <div class="flex flex-col justify-center pb-4 px-6 text-center">
                  <div class="mb-0">
                    <h2
                      class="font-bold text-2xl sm:text-4xl"
                      style="color: #1e435b"
                    >
                      If this is an emergency, <br />
                      dial 911 now.
                    </h2>
                  </div>
                </div>
              </div>
              <div class="flex flex-col">
                <div>
                  <a href="#" class="intercom_chat flex items-center py-4 sm:py-6 btn btn-1">
                    <span class="btn-icon">
                      <img src="images/chat_icon.png" alt="" />
                    </span>
                    <span class="pl-8 uppercase font-bold btn-text"
                      >Chat with us</span
                    >
                  </a>
                </div>
                <div>
                  <a @click="openExternalSite('https://www.thehopeline.com/topics/?utm_source=mobileapp&utm_medium=choosetopicpage&utm_campaign=gethelp-mobileapp')" class="flex items-center py-4 sm:py-6 btn btn-2">
                    <span class="btn-icon">
                      <img src="images/find_your_issue_icon.png" alt="" />
                    </span>
                    <span class="pl-8 uppercase font-bold btn-text"
                      >Find your issue</span
                    >
                  </a>
                </div>
                <div>
                  <a @click="openExternalSite('https://www.thehopeline.com/emailmentors/?utm_source=mobileapp&utm_medium=emailmentor&utm_campaign=gethelp-mobileapp')" class="flex items-center py-4 sm:py-6 btn btn-3">
                    <span class="btn-icon">
                      <img src="images/email_mentor.png" alt="" />
                    </span>
                    <span class="pl-8 uppercase font-bold btn-text"
                      >Request email mentor</span
                    >
                  </a>
                </div>
                <div>
                  <a @click="openExternalSite('https://www.thehopeline.com/ebook/?utm_source=mobileapp&utm_medium=ebooklibrary&utm_campaign=gethelp-mobileapp')" class="flex items-center py-4 sm:py-6 btn btn-4">
                    <span class="btn-icon">
                      <img src="images/ebooks_icon.png" alt="" />
                    </span>
                    <span class="pl-8 uppercase font-bold btn-text"
                      >Ebooks</span
                    >
                  </a>
                </div>
                <div>
                  <a @click="openExternalSite('https://linktr.ee/thehopeline')" class="flex items-center py-4 sm:py-6 btn btn-5">
                    <span class="btn-icon">
                      <img src="images/social_media.png" alt="" />
                    </span>
                    <span class="pl-8 uppercase font-bold btn-text"
                      >Social media</span
                    >
                  </a>
                </div>
              </div>
              <div class="flex flex-col justify-center pt-5 pb-2 px-6 text-center sm:pt-10 sm:pb-4">
                <div class="w-5/12 mx-auto sm:w-1/3">
                  <img src="images/chat_app_graphic.png" alt="" />
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { IonContent, IonPage } from "@ionic/vue";
import { Browser } from '@capacitor/browser';

const openExternalSite = async (url) => {
  await Browser.open({ url: url });
};
window.Intercom('update', {
  hide_default_launcher: false,
});
</script>

<style scoped>
.main-wrapper {
  background-image: url(../../public/images/background.png);
  background-repeat-y: repeat;
  background-size: contain;
}
.feedback-header {
  background-color: #1e435b;
}
.btn-1 {
  background-color: #dadfe2;
  color: #1e435b;
}
.btn-2 {
  background-color: #85becc;
  color: #ffffff;
}
.btn-3 {
  background-color: #f5e680;
  color: #1e435b;
}
.btn-4 {
  background-color: #1e435b;
  color: #ffffff;
}
.btn-5 {
  background-color: #e293b9;
  color: #1e435b;
}
.btn {
  padding-left: 12%;
  padding-right: 6%;
}
.btn-icon {
  width: 10%;
  min-width: 10%;
}
.btn-text {
  font-size: 120%;
  line-height: 1;
  letter-spacing: -1px;
}
@media (min-width: 375px) {
  .btn-text {
    font-size: 125%;
    line-height: 1;
  }
}
@media (min-width: 481px) {
  .btn-text {
    font-size: 200%;
    line-height: 1;
  }
}
@media (min-width: 590px) {
  .btn-text {
    font-size: 250%;
    line-height: 1;
  }
  .btn-icon {
    width: 7%;
    min-width: 7%;
  }
}
</style>
import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { IonicVue } from '@ionic/vue';
import VueGtag  from 'vue-gtag'
import { osName } from 'mobile-device-detect';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
// import '@ionic/vue/css/padding.css';
// import '@ionic/vue/css/float-elements.css';
// import '@ionic/vue/css/text-alignment.css';
// import '@ionic/vue/css/text-transformation.css';
// import '@ionic/vue/css/flex-utils.css';
// import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
/*TailWind CSS */
import './styles/app.css';

const os = osName;
let measurement_id='';
if(os==='iOS') {
  measurement_id = 'G-LWWJDM1L25'
}
else if(os==='Android') {
  measurement_id = 'G-EXSL48G1HM'
}
const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(VueGtag, {
    config: { id: measurement_id }
  }, router);
  
router.isReady().then(() => {
  app.mount('#app');
});
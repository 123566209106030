<template>
  <ion-page>
    <ion-content>
      <div class="app bg-slate-50 h-full">
        <div class="max-w-screen-lg mx-auto h-full">
          <main class="main-wrapper h-full">
            <div class="main-content h-full">
              <div class="feedback-header grid gap-4 grid-cols-3 grid-rows-1 text-white px-5 pt-3 pb-2">
                <div class="text-left">
                  <router-link to="/feedback" class="inline-flex items-center no-underline">
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 48 48" fill="currentColor"><path d="M20 44 0 24 20 4l2.8 2.85L5.65 24 22.8 41.15Z"/></svg>               
                    </span>
                    <span class="font-medium">Feedback</span>
                  </router-link>
                </div>
                <div class="text-center">
                  <span class="font-medium">Feedback</span>
                </div>
                <div class="text-right">
                  <span class="font-medium" @click.prevent="storeContact">Submit</span>
                </div>
              </div>

              <div class="feedback-content flex flex-col">
                <div class="feedback-info bg-white px-5 py-4">
                  <h2 class="text-2xl font-bold mb-2">Thank you for providing your feedback. If this is an emergency, please call 9-1-1.</h2>
                  <p class="text-base">We apologize for any inconvenience you may experience when using our app. Please provide as much detail as possible below so we can troubleshoot your problem. Thank you.</p>
                </div>
                <div class="feedback-form">
                  <form>
                    <div class="flex px-5 py-4 border-slate-300 border-b border-solid">
                      <span class="text-sm mr-2 whitespace-nowrap">Email Address:</span>
                      <span class="grow">
                        <input type="email" v-model.trim = "user.email" name="" class="w-full bg-transparent text-sm feedback-input block font-medium">
                      </span>
                    </div>
                    <div class="flex px-5 py-4 border-slate-300 border-b border-solid">
                      <span class="text-sm mr-2 whitespace-nowrap">Phone Number:</span>
                      <span class="grow">
                        <input type="text" v-model.trim = "user.phone" name="" class="w-full bg-transparent text-sm feedback-input block font-medium">
                      </span>
                    </div>
                    <div class="flex px-5 py-4 border-slate-300 border-b border-solid">
                      <span class="text-sm mr-2 whitespace-nowrap">Details Of the issue:</span>
                      <span class="grow">
                        <input type="text" v-model.trim = "user.detail" name="" class="w-full bg-transparent text-sm feedback-input block font-medium">
                      </span>
                    </div>
                    <div class="flex px-5 py-4">
                      <span class="text-sm mr-2 whitespace-nowrap">Time of day problem occured:</span>
                      <span class="grow">
                        <input type="text" v-model.trim = "user.time" name="" class="w-full bg-transparent text-sm feedback-input block font-medium">
                      </span>
                    </div>
                  </form>
                </div>

                <div class="feedback-device-info">
                  <div class="flex px-5 py-4 border-slate-500 border-b border-solid">
                      <span class="text-sm mr-2 whitespace-nowrap">Device info:</span>
                      <span class="grow text-sm font-medium">{{ osName }} {{ osVersion }}</span>
                    </div>
                    <div class="flex px-5 py-4 border-slate-500 border-b border-solid">
                      <span class="text-sm mr-2 whitespace-nowrap">Manufacturer:</span>
                      <span class="grow text-sm font-medium">{{ mobileVendor }} Inc  Model {{ mobileModel }}</span>
                    </div>
                    <div class="flex px-5 py-4 border-slate-500 border-b border-solid">
                      <span class="text-sm mr-2 whitespace-nowrap">Cerculor:</span>
                      <span class="grow text-sm font-medium">{{ osName }}</span>
                    </div>
                    <div class="flex px-5 py-4">
                      <span class="grow text-sm font-medium">{{ getUA }}</span>
                    </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-10" @close="open = false">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
              <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <DialogPanel class="relative transform  rounded-lg bg-white px-4 pt-4 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div class="absolute close-button block">
                    <button type="button" class="rounded-full bg-white px-1 py-1 text-gray-400 hover:text-gray-500 focus:outline-none" @click="open = false">
                      <span class="sr-only">Close</span>
                      <span><svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z" fill="currentColor"/><path fill-rule="evenodd" clip-rule="evenodd" d="M18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L6.53035 18.5303C6.23745 18.8232 5.76258 18.8232 5.46969 18.5303C5.17679 18.2374 5.17679 17.7626 5.46968 17.4697L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967Z" fill="currentColor"/></svg></span>
                    </button>
                  </div>
                  <div>
                    <div class="text-center">
                      <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900"  v-if="errorEmail!='' || errorPhone!='' ">
                        <ul>
                          <li class="text-sm text-red-700" v-if="errorEmail">{{ errorEmail }}</li>
                          <li class="text-sm text-red-700" v-if="errorPhone">{{ errorPhone }}</li>
                        </ul>
                      </DialogTitle>

                      <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900" v-if="status">
                        <div v-if="status=='success'">
                          We are sorry you were having issues. We will work to resolve them as quickly as possible.
                        </div>
                        <div v-else>
                          Something went wrong. Please try again later!
                        </div>
                      </DialogTitle>

                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { IonContent, IonPage } from "@ionic/vue"
import { ref, reactive } from 'vue'
import { mobileModel, mobileVendor, osName, osVersion, getUA  } from 'mobile-device-detect';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import axios from "axios"

const open = ref(false)
const errorEmail = ref()
const errorPhone = ref()
const status = ref()

const user = reactive({
    phone: '',
    email: '',
    detail: '',
    time: '',
    osName: osName,
    osVersion: osVersion,
    mobileModel: mobileModel,
    mobileVendor: mobileVendor,
    getUA: getUA,
})


const storeContact = () => {
  // Email Validation
  if(user.email == null || user.email == '') {
    errorEmail.value = "Please enter email address."
  } else if (/^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,20})$/.test(user.email)) {
    errorEmail.value = "";
  } else {
    errorEmail.value = "Please enter a valid email address.";
  }

  // Phone Validation
  if(user.phone == null || user.phone == '') {
    errorPhone.value = "Please enter phone number."
  } else if (/(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g.test(user.phone)) {
    errorPhone.value = "";
  } else {
    errorPhone.value = "Please enter a valid phone.";
  }
  
  if(errorEmail.value!='' || errorPhone.value!='') {
    status.value = ''
    open.value = true
  }

  if(errorEmail.value=='' && errorPhone.value=='') {
    axios.post("https://www.thehopeline.com/mail.php", user)
    .then((res) => {
      status.value = res.data.status
      open.value = true
      user.phone = ''
      user.email = ''
      user.detail = ''
      user.time= ''
    })
  }
}
window.Intercom('update', {
  hide_default_launcher: true,
});
</script>

<style scoped>
.main-wrapper {
  background-color: #1e435b;
}
.feedback-header {
  background-color: #1e435b;
}
.feedback-info {
  color: #1e435b;
}
.feedback-form {
  background-color: #85becc;
  color: #ffffff;
}
.feedback-device-info {
    background-color: #f5e680;
    color: #1e435b;
}
.feedback-content {
  height: calc(100% - 50px);
}
.close-button {
  top: -36px;
  right: 0px;
}
</style>
import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '@/views/HomePage.vue';
import GetHelpPage from '@/views/GetHelpPage.vue';
import FeedBackPage from '@/views/FeedBackPage.vue';
import FeedBackFormPage from '@/views/FeedBackFormPage.vue';

const routes: Array<RouteRecordRaw> = [
  
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/gethelp',
    name: 'GetHelp',
    component: GetHelpPage
  },
  {
    path: '/feedback',
    name: 'FeedBack',
    component: FeedBackPage
  },
  {
    path: '/feedback-form',
    name: 'FeedBackForm',
    component: FeedBackFormPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
